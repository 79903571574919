/*!
 * Start Bootstrap - SB Admin 2 Bootstrap Admin Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

.modal-content #loadingDiv {
    background: none repeat scroll 0 0 black;
    height: 100%;
    opacity: 0.91;
    position: absolute;
    width: 100%;
    z-index: 10;
}
.modal-content #loadingDiv i {
    color: white;
    left: -50px;
    margin-left: 50%;
    margin-top: 100px;
    position: relative;
}
.modal-body li {
    list-style-type: none;
}
.sidebar-nav .glyphicon {
    top:2px;
}
.editName {
    cursor:pointer;
}
.editName input[type="text"] {
    width:100%;
    padding:2px 5px;
}
.panel-ust {
    position: absolute;
    right: 0;
    top: -10px;
    z-index: 100;
}
.white {
    color:#ffffff;
}
#addOutgoingForm, #addIncomingForm {
    margin: 0 auto;
    max-width: 1100px;
}

#closeErrorPanel {
    color:inherit;
}

#dataTables-example_filter label input {
    margin-left:10px;
}
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus , .nav li.active > a, .nav li a:focus {
    background-color: #111;
}

.navbar-brand {
    width:110px;
}
.navbar-brand img {
    max-width:200px;
}
/*!
 * Some margins
 */
.top0-i {margin-top:0 !important;}
.top0 {margin-top:0}
.top5 { margin-top:5px; }
.top7 { margin-top:7px; }
.top10 { margin-top:10px; }
.top15 { margin-top:15px; }
.top17 { margin-top:17px; }
.top30 { margin-top:30px; }

.bottom5 { margin-bottom:5px; }
.bottom7 { margin-bottom:7px; }
.bottom10 { margin-bottom:10px; }
.bottom15 { margin-bottom:15px; }
.bottom17 { margin-bottom:17px; }
.bottom30 { margin-bottom:30px; }

.left5 { margin-left:5px; }
.left7 { margin-left:7px; }
.left10 { margin-left:10px; }
.left15 { margin-left:15px; }
.left17 { margin-left:17px; }
.left30 { margin-left:30px; }

.right5 { margin-right:5px; }
.right7 { margin-right:7px; }
.right10 { margin-right:10px; }
.right15 { margin-right:15px; }
.right17 { margin-right:17px; }
.right30 { margin-right:30px; }

.modal-body .list-group-item span {
    display:block;
}
.modal-body .list-group-item {
    height:40px;
}
body {
    background-color: #f8f8f8;
    /*background-color:#222222;*/
}

.navbar-default {
    border-bottom:none;
}

#wrapper {
    width: 100%;
}

#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
    background-color: #fff;
}

@media(min-width:768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 200px;
        padding: 0 30px;
        border-left: 1px solid #e7e7e7;
    }
}
.navbar-default {
    background: #222222;
    height:100px;
}
.navbar-top-links {
    margin-right: 0;
}

.navbar-top-links li {
    display: inline-block;
}

.navbar-top-links li:last-child {
    margin-right: 15px;
}

.navbar-top-links li a {
    padding: 15px;
    min-height: 50px;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 3px 20px;
    min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}
.navbar-default a {
    color:white;
}
.sidebar .sidebar-nav.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
}

.sidebar .sidebar-search {
    padding: 15px;
}

.sidebar ul li {
    /*border-bottom: 1px solid #e7e7e7;*/
    border-bottom:#222222;
}

.sidebar ul li a.active {
    /*background-color: #eee;*/
    background-color:#111111;
}
.nav > li > a:hover,
.nav > li > a:focus{
    background:#111111;
}

.nav a:focus {
    outline:none;
}
.sidebar .arrow {
    float: right;
}

.sidebar .fa.arrow:before {
    content: "\f104";
}

.sidebar .active>a>.fa.arrow:before {
    content: "\f10gulp";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: 0!important;
}

.sidebar .nav-second-level li a {
    padding-left: 37px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

@media(min-width:768px) {
    .sidebar {
        z-index: 1;
        position: absolute;
        width: 200px;
        margin-top: 100px;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.chat {
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #999;
}

.chat li.left .chat-body {
    margin-left: 60px;
}

.chat li.right .chat-body {
    margin-right: 60px;
}

.chat li .chat-body p {
    margin: 0;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.chat-panel .panel-body {
    height: 350px;
    overflow-y: scroll;
}

.login-panel {
    margin-top: 25%;
}

.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: 0 0;
}

table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting:after {
    content: "\f0dc";
    float: right;
    font-family: fontawesome;
    color: rgba(50,50,50,.5);
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee!important;
}

.show-grid {
    margin: 15px 0;
}

.huge {
    font-size: 40px;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}