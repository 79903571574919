@import "variables_admin";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/metismenu/dist/metisMenu";
@import "node_modules/font-awesome/css/font-awesome";
@import "../css/sb-admin-2";

@import "node_modules/datatables.net-bs/css/dataTables.bootstrap";
@import "node_modules/datatables.net-responsive-bs/css/responsive.bootstrap";
@import "node_modules/sweetalert/dist/sweetalert";
@import "node_modules/sweetalert/themes/google/google.scss";
@import "node_modules/bootstrap-dialog/dist/css/bootstrap-dialog.min";
@import "node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min";
@import "node_modules/dropzone/dist/dropzone";


div.dataTables_wrapper div.dataTables_processing {
  background: #fff url("../images/loading2.gif") no-repeat scroll center 38px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px #444;
  color: #444;
  font-size: 14px;
  height: 80px;
  left: 50%;
  margin-left: -125px;
  margin-top: -15px;
  padding: 15px 0 30px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 250px;
  z-index: 9999;
}

#wrapper {
  background: $janner-main-color;
}
.navbar-static-top {
  height:80px;
  padding: 15px 0 15px 15px;
}
.navbar-default {
  background: $janner-main-color;
}
.sidebar {
  margin-top:20px;
}

nav .open > a, .nav .open > a:hover, .nav .open > a:focus, .nav li.active > a, .nav li a:focus {
  background-color: darken($brand-primary, 10%);
}

.nav li.active > a {
  background: $janner-main-color;
}

.nav > li > a:hover, .nav > li > a:focus {
  background: $janner-main-color;
}

.error {
  color:red;
}

h1.page-header {
  color: $janner-main-color;
  font-size: 20px;
  font-weight: bold;
}
#side-menu h3 {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-left: 25px;
}

.nav > li > a {
  display: block;
  padding: 4px 15px;
  position: relative;
  margin-left:10px;
}

.navbar-brand img {
  width:160px;
}
.navbar-brand {
  width:auto !important;
}

legend {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: currentcolor currentcolor #e5e5e5;
  border-image: none;
  border-style: none none solid;
  border-width: 0 0 1px;
  color: #636b6f;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: inherit;
  margin-bottom: 22px;
  padding: 0;
  width: 100%;
}


.navbar-static-top {
  height: 65px;
  padding: 15px 0 15px 15px;
  background: $janner-main-color !important;
}

#wrapper {
  background: $janner-secondary-color;
}
.navbar-default {
  background: $janner-secondary-color none repeat scroll 0 0;
}

.nav > li > a:hover, .nav > li > a:focus {
  background: $janner-main-color none repeat scroll 0 0;
}


.bootstrap-dialog.type-info .modal-header {
  background-color: $janner-main-color;
}
.close {
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  opacity: 1;
  text-shadow: initial;
  float: right;
}

.dropzone {
  border: 2px dotted #1b6d85;
}

